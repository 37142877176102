
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { actionSaveSource } from '../../actions';

const PublicRoute = () => {
    const location = useLocation();
    
    useEffect(() => {
        const urlParams = new URLSearchParams(location?.search);
        const source = urlParams.get('source');
        if(source) actionSaveSource(source);
    }, [])

    return <Outlet/>
};
  
export default PublicRoute;