// GENERAL TYPES
export const COUNTRY_ID = 'COUNTRY_ID';
export const CHANNEL_ID = 'CHANNEL_ID';
export const USER_ACCESS_TOKEN = 'USER_ACCESS_TOKEN';
export const LOADING = 'LOADING';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const SUCCESS_CARD_TEXT = 'SUCCESS_CARD_TEXT';
export const BURGER_OPEN = 'BURGER_OPEN';
export const RESQUEST_SUCCESS_POLICY = 'RESQUEST_SUCCESS_POLICY';
export const GLOBAL_FORM_ANSWER = 'GLOBAL_FORM_ANSWER';
export const GLOBAL_PARAMS = 'GLOBAL_PARAMS';

// COTIZAR TYPES
export const FETCH_CATEGORIAS = 'FETCH_CATEGORIAS';
export const CATEGORIA_SELECTED = 'CATEGORIA_SELECTED';

// MAINCOTIZAR TYPES
export const OFFER_DATA = 'OFFER_DATA';
export const ORIGINAL_OFFER_DATA = 'ORIGINAL_OFFER_DATA';
export const SHOW_RESTRICTIONS = 'SHOW_RESTRICTIONS';
export const RESTRICTIONS_TEXT = 'RESTRICTIONS_TEXT';
export const CONTACT_METHODS = 'CONTACT_METHODS';
export const CONTACT_WHATSAPP = 'CONTACT_WHATSAPP';

//CHECKOUT TYPES
export const CHECKOUT_DATA = 'CHECKOUT_DATA';
export const CHECK_OUT_PRODUCT_REQUIREMENTS = 'CHECK_OUT_PRODUCT_REQUIREMENTS';
export const CHECK_OUT_PAYMENT_OPTIONS = 'CHECK_OUT_PAYMENT_OPTIONS';
export const USER_CREDIT_CARDS = 'USER_CREDIT_CARDS';
export const CARD_SELECTED_CHECKOUT = 'CARD_SELECTED_CHECKOUT';
export const CVV_TEXT_INPUT = 'CVV_TEXT_INPUT';
export const CHECKOUT_DATA_PAYMENT_INFO = 'CHECKOUT_DATA_PAYMENT_INFO';
export const PROMO_CODE_ERROR = 'PROMO_CODE_ERROR';
export const CHECK_OUT_CONFIRM_START = 'CHECK_OUT_CONFIRM_START';
export const CHECK_OUT_CONFIRM_SUCCESS = 'CHECK_OUT_CONFIRM_SUCCESS';
export const CHECK_OUT_CONFIRM_ERROR = 'CHECK_OUT_CONFIRM_ERROR';
export const CHECK_OUT_CONFIRM_ERROR_MESSAGE = 'CHECK_OUT_CONFIRM_ERROR_MESSAGE';
export const CHECK_OUT_CONFIRM_SUCCESS_PRODUCT = 'CHECK_OUT_CONFIRM_SUCCESS_PRODUCT';
export const CHECK_OUT_CONFIRM_SUCCESS_USER = 'CHECK_OUT_CONFIRM_SUCCESS_USER';
export const STEP_TRES = 'STEP_TRES';
export const CHECK_OUT_CONFIRM_DATA = 'CHECK_OUT_CONFIRM_DATA';
export const CHECK_OUT_CBU = 'CHECK_OUT_CBU';
export const CHECK_OUT_NUMERO_TARJETA = 'CHECK_OUT_NUMERO_TARJETA';
export const CHECK_OUT_TIPO = 'CHECK_OUT_TIPO';
export const CHECK_OUT_EMISORA_TYPE = 'CHECK_OUT_EMISORA_TYPE';
export const CHECK_OUT_REFERER = 'CHECK_OUT_REFERER';
export const CHECK_OUT_REFERER_APPLIED = 'CHECK_OUT_REFERER_APPLIED';
export const CHECKOUT_DATA_USER = 'CHECKOUT_DATA_USER';
export const CHECK_OUT_REFERER_ERROR = 'CHECK_OUT_REFERER_ERROR';
export const CHECKOUT_DATA_HOME = 'CHECKOUT_DATA_HOME';

//LOGIN TYPES
export const EMAIL_VALIDATE = 'EMAIL_VALIDATE';
export const USER_ACCESS_TOKEN_AUX = 'USER_ACCESS_TOKEN_AUX';
export const RESEND_VALID_CODE_SUCCESS = 'RESEND_VALID_CODE_SUCCESS';

//USER TYPES
export const USER_DATA = 'USER_DATA';
export const CIVIL_STATUS_TYPES = 'CIVIL_STATUS_TYPES';
export const SEX_TYPES = 'SEX_TYPES';
export const STATES = 'STATES';
export const STATES_STRINGS = 'STATES_STRINGS';
export const LOCALIDAD_EDIT_CHANGED = 'LOCALIDAD_EDIT_CHANGED';
export const LOCALIDAD_STRING_EDIT_CHANGED = 'LOCALIDAD_STRING_EDIT_CHANGED';
export const ADDRESS_LOCALITY_SELECTED = 'ADDRESS_LOCALITY_SELECTED';
export const PROVINCIA_EDIT_CHANGED = 'PROVINCIA_EDIT_CHANGED';
export const MAP_COORDINATES = 'MAP_COORDINATES';
export const ADDRESS_SELECTED_PROFILE = 'ADDRESS_SELECTED_PROFILE';
export const LOADING_ADDRESS_STREET_PROFILE = 'LOADING_ADDRESS_STREET_PROFILE';
export const ADDRESS_STREET_PROFILE = 'ADDRESS_STREET_PROFILE';
export const ADDRESS_SELECTED_PLACE_ID = 'ADDRESS_SELECTED_PLACE_ID';
export const ADDRESS_STREET_RESULTS_PROFILE = 'ADDRESS_STREET_RESULTS_PROFILE';
export const ADDRESS_STREET_SHOW_DEFAULT = 'ADDRESS_STREET_SHOW_DEFAULT';
export const DIRECCION_CHANGE = 'DIRECCION_CHANGE';
export const CODIGO_POSTAL_CHANGE = 'CODIGO_POSTAL_CHANGE';
export const GM_ADDRESS_FORMAT_CHANGE = 'GM_ADDRESS_FORMAT_CHANGE';
export const PROVINCIA_CHANGE = 'PROVINCIA_CHANGE';
export const DELEGACION_CHANGE = 'DELEGACION_CHANGE';
export const COLONIA_CHANGE = 'COLONIA_CHANGE';
export const FETCH_CPS_BY_CITY = 'FETCH_CPS_BY_CITY';
export const NOMBRE = 'NOMBRE';
export const APELLIDO = 'APELLIDO';
export const EMAIL = 'EMAIL';
export const CELULAR = 'CELULAR';
export const DNI = 'DNI';
export const GENERO = 'GENERO';
export const FECHA_NAC = 'FECHA_NAC';
export const CUIL = 'CUIL';
export const ESTADO_CIVIL = 'ESTADO_CIVIL';
export const PISO = 'PISO';
export const FETCH_CITIES_BY_STATES = 'FETCH_CITIES_BY_STATES';
export const USER_PLACES = 'USER_PLACES';
export const USER_PLACE = 'USER_PLACE';
export const USER_PLACES_MUNICIPIOS = 'USER_PLACES_MUNICIPIOS';
export const USER_PLACE_MUNICIPIO = 'USER_PLACE_MUNICIPIO';
export const USER_PLACES_LOCALIDADES = 'USER_PLACES_LOCALIDADES';
export const USER_PLACE_LOCALIDAD = 'USER_PLACE_LOCALIDAD';
export const PROFILE_ENABLE_EDIT = 'PROFILE_ENABLE_EDIT';
export const PROFILE_SHOW_MORE = 'PROFILE_SHOW_MORE';
export const APELLIDO_MATERNO = 'APELLIDO_MATERNO';
export const RUT = 'RUT';
export const DV = 'DV';

//TARJETAS TYPES
export const FETCH_TYPES_BANKS_ARRAY = 'FETCH_TYPES_BANKS_ARRAY';
export const EMISORAS_TYPES_ARRAY = 'EMISORAS_TYPES_ARRAY';
export const BANCO_TARJETA_ID = 'BANCO_TARJETA_ID';
export const TIPO_TARJETA_ID = 'TIPO_TARJETA_ID';
export const FETCH_BANK_ARRAY = 'FETCH_BANK_ARRAY';
export const NUMERO_TARJETA = 'NUMERO_TARJETA';
export const DNI_TARJETA = 'DNI_TARJETA';
export const TITULAR_TARJETA = 'TITULAR_TARJETA';
export const FECHA_TARJETA = 'FECHA_TARJETA';
export const USER_LOADER = 'USER_LOADER';

//REF TYPES
export const CATEGORIA_REF = 'CATEGORIA_REF';
export const PREGUNTAS_REF = 'PREGUNTAS_REF';
export const RESUMEN_REF = 'RESUMEN_REF';
export const PAYMENT_REF = 'PAYMENT_REF';
export const PRODUCT_REF = 'PRODUCT_REF';
export const PROFILE_REF = 'PROFILE_REF'

//HOME TYPE
export const USER_PRODUCTS_INACTIVE = 'USER_PRODUCTS_INACTIVE';
export const USER_PRODUCTS_ACTIVE = 'USER_PRODUCTS_ACTIVE'

//RECOVERY LOGIN
export const RECOVERY_PASSWORD_SUCCESS = 'RECOVERY_PASSWORD_SUCCESS';
export const RECOVERY_PASSWORD_ID = 'RECOVERY_PASSWORD_ID';


//SUGGEST TYPES 
export const FETCH_SUGERIDOS_LIST_USER = 'FETCH_SUGERIDOS_LIST_USER';
export const FETCH_URL_SUGERIDOS = 'FETCH_URL_SUGERIDOS';

//RECLAMO TYPES
export const CLAIM_TYPES = 'CLAIM_TYPES';
export const CLAIM_PROCESS_DATA = 'CLAIM_PROCESS_DATA';
export const USER_RECLAMO_LIST = 'USER_RECLAMO_LIST';