import React from 'react'
import './QuoteQuestion.css'

const QuoteQuestion = (props: any) => {

    const getQuestionNumber = () => {
        return props.data?.previousQuestions?.length + 1;
    }

    return (
        <div className="quoteQuestion-container">
            {props.canGoBack && <div className="quoteQuestion-container-volver" onClick={() => props.goBack()}>
                <span className="material-symbols-outlined" style={{ color: 'var(--primary500)', fontSize: 18 }}>chevron_left</span>
                <b className="quoteQuestion-container-volver-text bold medium">Volver</b>
            </div>}
            <div className="quoteQuestion-container-step">
                <p className="quoteQuestion-container-step-paso bold">Paso {getQuestionNumber()}:&nbsp;</p>
                <p className="quoteQuestion-container-step-pregunta bold">{props.data?.incompleteQuestions[0]?.display?.name}</p>
            </div>
        </div>
    );
}

export default QuoteQuestion;