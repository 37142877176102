import React, { useEffect, useState } from 'react';
import './Header.css';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import { 
  changeCountryId,
  setBurgerOpen 
} from '../../../actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logoBlanco from '../../../assets/images/svgs/logoBlanco.svg';
import logoColor from '../../../assets/images/svgs/logoColor.svg';
import { GetCountryUrl } from '../../../util/GetCountryUrl';

let isTabletOrMobile = false;

const Header = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
  const [admin, setAdmin] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleUrlParams = () => {
    let admin: any = searchParams.get("admin");
    if (admin) {
      setAdmin(true)
    }
  }

  useEffect(() => {
    handleUrlParams()
  }, [])

  return (
    <div
      className={props.fixed ? "header-containerFixed" : "header-container"}
      style={{
        display: admin ? 'none' : 'flex',
        backgroundColor: 
          props.trans ? 'var(--primary500)' :
          props.home || props.admin ? 'transparent' :
          props.backgroundColor ? props.backgroundColor : 
          props.color ? props.color : 'var(--primary500)',
      }}
    >
      <div className="header-container-main">
        <div className="header-container-main-logos">
          <img 
            onClick={() => {
              if(props.userAccessToken) {
                navigate(GetCountryUrl(props.countryId) + 'home')
              } else {
                navigate(GetCountryUrl(props.countryId) + 'inicio')
              }
            }}
            className="header-container-main-logo"
            src={props.home && !props.trans && !props.blanco ? logoColor : logoBlanco} 
            alt=""
          />
        </div>
        <span 
          className="material-symbols-outlined" 
          style={{
            fontSize: 40, 
            cursor: 'pointer', 
            transition: 'all 0.5s ease-in',
            color: props.home && !props.trans && !props.blanco ? 'var(--primary500)' : 'var(--white)'
          }}
          onClick={() => props.setBurgerOpen(true)}
          id='burger-menu'
        >menu</span>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    userAccessToken: state.general.userAccessToken
  };
};

export default connect(mapStateToProps, {
  changeCountryId,
  setBurgerOpen
})(Header);