import React, {useEffect, useState} from 'react'
import CustomTextInput from '../../generalComponents/customTextInput/CustomTextInput';
import MainButton from '../../generalComponents/MainButton/MainButton';
import './CheckOutProfile.css'
import {
    createUserProfileArg,
} from '../../../actions'
import {connect} from 'react-redux';
import Moment from 'moment/moment'
import { useMediaQuery } from 'react-responsive'
import DiscountDetailsComponent from '../DiscountDetailsComponent/DiscountDetailsComponent';
import { FormattedMessage } from 'react-intl';
import { useSearchParams, useLocation } from 'react-router-dom';

let isTabletOrMobile = false;
const CheckOutProfile = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //Inputs
    const [nombre, setNombre] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');

    const [celular, setCelular] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    
    //OTROS
    const [admin, setAdmin] = useState<boolean>(false);
    const [authUserId, setAuthUserId] = useState<any>(null);
    const [searchParams] = useSearchParams();
    const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
    const location: any = useLocation();
    const adminSelectedUser: any = location?.state?.adminSelectedUser 

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    }

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isDisabled = () => {
        if(nombre === '' || apellido === '' || celular === '' || !validateEmail(email)) return true
        return false
    }

    console.log(props.checkOutData)

    return (
        <div className="checkOutProfile-container">
            <div className="checkOutProfile-container-main">
                <div 
                    className="checkOutProfile-container-main-volver"
                    onClick={() => window.history.back()}
                >
                    <span className="material-symbols-outlined" style={{ fontSize: 18 }}>arrow_back_ios_new</span>
                    <b className="checkOutProfile-container-main-volver-text bold medium">Volver</b>
                </div>
                <h6 className="checkOutProfile-container-main-titulo bold">Datos personales</h6>
                {!props.userAccessToken && <p className="checkOutProfile-container-main-reLogText"><FormattedMessage id="page.checkOut.checkOutProfile.reLogin" defaultMessage="" /><p onClick={() => props.reLog()}>&nbsp;Iniciá sesión</p></p>}
                <div className="checkOutProfile-container-main-list">
                    <CustomTextInput
                        label='Nombre'
                        required
                        changeText={(e: string) => setNombre(e)}
                        value={nombre}
                        error={!isValidName(nombre)}
                        success={nombre !== '' ? isValidName(nombre) : false}
                    />
                    <CustomTextInput
                        label='Apellido'
                        required
                        changeText={(e: string) => setApellido(e)}
                        value={apellido}
                        error={!isValidName(apellido)}
                        success={apellido !== '' ? isValidName(apellido) : false}
                    />
                    <div className="checkOutProfile-container-main-list-invis"></div>
                    <CustomTextInput
                        label='Celular'
                        required
                        maxLength={10}
                        changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
                        value={celular}
                        success={celular !== ''}
                    />
                    <CustomTextInput
                        label='E-mail'
                        required
                        value={email}
                        changeText={(e: string) => setEmail(e)}
                        success={validateEmail(email)}
                        error={email.length > 0 ? !validateEmail(email) : false}
                        errorMessage='E-mail inválido'
                        placeholder='nombre@ejemplo.com'
                    />
                    <div className="checkOutProfile-container-main-list-invis"></div>
                </div>
            </div>
            <div className="checkOutProfile-container-endWrapper">
                <div className="checkOutProfile-container-endWrapper-main">
                    <DiscountDetailsComponent/>
                    <MainButton
                        id='Continuar Boton' 
                        fill
                        color='var(--secondary500)'
                        disabled={isDisabled()}
                        text='CONTINUAR'
                        onButtonPressed={() => {
                            const fastUserData = {
                                'name': nombre,
                                'lastName': apellido,

                                'phone': celular,
                                'userName': email,
                            }
                            props.createUserProfileArg(
                                fastUserData,
                                props.checkOutData?.id, 
                                admin,
                                props.checkOutData?.offer?.packages?.filter((a: any) => a.id = props.checkOutData?.offer?.currentPackage)[0]?.insurer?.id,
                                appBaseIframe
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    userAccessToken: state.general.userAccessToken,
    checkOutData: state.checkOut.checkOutData
  }; 
};

export default connect(mapStateToProps, {
    createUserProfileArg
})(CheckOutProfile);