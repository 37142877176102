import React, {useEffect, useState} from 'react'
import './PerfilHome.css'
import {
    getDataForNewUserArgPardo,
    getLocalidadesByProvinciaPardo,
    getCodigosPostalesByLocalidadPardo,
    loadDataForLoggedUserArgPardo,
    saveEditedData
} from '../../actions'
import {connect} from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import CustomTextInput from '../../components/generalComponents/customTextInput/CustomTextInput';
import SelectorSearcher from '../../components/generalComponents/selectorSearcher/SelectorSearcher';
import Header from '../../components/generalComponents/header/Header';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import Moment from 'moment/moment'
import { useNavigate } from 'react-router-dom';
import Notification from '../../components/generalComponents/Notification/Notification';

let isTabletOrMobile = false;
const PerfilHome = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

    //Inputs
    const [nombre, setNombre] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');
    const [fechaNac, setFechaNac] = useState<string>('');

    const [tipoDocu, setTipoDocu] = useState<any>(null);
    const [numDocu, setNumDocu] = useState<string>('');
    const [cuit, setCuit] = useState<string>('');

    const [cuitFirst, setCuitFirst] = useState<string>('');
    const [cuitSecond, setCuitSecond] = useState<string>('');
    const [cuitThird, setCuitThird] = useState<string>('');
    
    const [sexo, setSexo] = useState<any>(null);
    const [estadoCivil, setEstadoCivil] = useState<any>(null);
    const [nacionalidad, setNacionalidad] = useState<any>(null);

    const [celular, setCelular] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [tipoDomi, setTipoDomi] = useState<any>(null);
    const [calle, setCalle] = useState<string>('');
    const [nro, setNro] = useState<string>('');
    const [piso, setPiso] = useState<string>('');
    const [dpto, setDpto] = useState<string>('');

    const [provincia, setProvincia] = useState<any>(null);
    const [localidad, setLocalidad] = useState<any>(null);
    const [codigoPostal, setCodigoPostal] = useState<any>(null);

    //Listado de Selectors
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);

    const [sexoList, setSexoList] = useState<any>(null);
    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [nacionalidadList, setNacionalidadList] = useState<any>(null);

    const [tipoDomiList, setTipoDomiList] = useState<any>(null);

    const [provinciaList, setProvicinaList] = useState<any>(null);
    const [localidadList, setLocalidadList] = useState<any>(null);
    const [codigosPostalesList, setCodigosPostalesList] = useState<any>(null);

    //OTROS
    const [authUserId, setAuthUserId] = useState<any>(null);
    const [editing, setEditing] = useState<boolean>(false);
    const [notification, setNotification] = useState({ success: false, text: '' });

    const navigate = useNavigate();

    useEffect(() => {
        props.getDataForNewUserArgPardo(
            setTipoDocuList, setSexoList, setEstadoCivilList, 
            setNacionalidadList, setTipoDomiList, setLocalidadList, setProvicinaList
        );
    }, [])

    useEffect(() => {
        if(cuit?.length === 11) {
            setCuitFirst(cuit?.slice(0, 2))
            setCuitSecond(cuit?.slice(2, cuit?.length -1))
            setCuitThird(cuit?.slice(cuit?.length - 1, cuit.length))
        }
    }, [cuit])

    useEffect(() => {
      if(props.userAccessToken || notification?.success) {
        props.loadDataForLoggedUserArgPardo(
            setNombre, setApellido, setFechaNac,
            setTipoDocu, setNumDocu, setCuit,
            setSexo, setEstadoCivil, setNacionalidad,
            setCelular, setEmail,
            setTipoDomi, setCalle, setNro, setPiso, setDpto,
            setProvincia, setLocalidad, setCodigoPostal,
            setLocalidadList, setCodigosPostalesList, setAuthUserId
        );
      }
    },[props.userAccessToken, notification])

    const isValidDate = (e: string) => {
        if(e?.length === 10) {
            if(Moment(e, 'YYYY-MM-DD').isValid()) return true
        }
        return false
    } 

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isDisabled = () => {
        if(nombre === '' || apellido === '' || !isValidDate(fechaNac) || 
            !tipoDocu || numDocu === '' || (cuitFirst.length !== 2 || cuitSecond.length !== 8 || cuitThird.length !== 1) || 
            !sexo || !estadoCivil || !nacionalidad ||
            celular === '' || !validateEmail(email) ||
            !tipoDomi || calle === '' || nro === '' ||
            !localidad || !codigoPostal || !provincia) {
                return true
        } return false
    }

    useEffect(() => {
        if(numDocu?.length === 8) setCuitSecond(numDocu)
        if(numDocu?.length === 0) setCuitSecond(numDocu)
    }, [numDocu])

    return (
        <div className="perfilHome-container">
            <Header home colored special />
            <Notification
                notification={notification}
                setNotification={setNotification}
            />
            <div className="perfilHome-container-main">
                <h4 className="perfilHome-container-main-superTitulo bold">Mi Perfil</h4>
                <h6 className="perfilHome-container-main-titulo bold">Datos personales</h6>
                <div className="perfilHome-container-main-list">
                    <CustomTextInput
                        label='Nombre'
                        required
                        value={nombre}
                        changeText={(e: string) => setNombre(e)}
                        disabled
                    />
                    <CustomTextInput
                        label='Apellido'
                        required
                        value={apellido}
                        changeText={(e: string) => setApellido(e)}
                        disabled
                    />
                    <CustomTextInput
                        label='Fecha de nacimiento'
                        required
                        changeText={(e: string) => setFechaNac(e)}
                        value={fechaNac}
                        error={fechaNac?.length >= 0 && !isValidDate(fechaNac)}
                        errorMessage={'Fecha Invalida'}
                        success={isValidDate(fechaNac)}
                        maxLength={10}
                        placeholder="DD/MM/AAAA"
                        type="date"
                        max='2999-12-31'
                        disabled={!editing}
                    />
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: editing ? 1 : 0.5  }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Tipo de documento&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de documento'
                            list={tipoDocuList}
                            onValueSelected={(a: any) => setTipoDocu(a.codigo)}
                            selectedItem={tipoDocuList && tipoDocu ? tipoDocuList?.filter((a: any) => a.codigo == tipoDocu)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu tipo de documento'
                            deleteSelectedItem={() => setTipoDocu(null)}
                            disabled={!tipoDocuList && !editing}
                        /> 
                    </div>
                    <CustomTextInput
                        label='Nro de documento'
                        required
                        changeText={(e: string) => setNumDocu(e.replace(/[^0-9]+/g, ''))}
                        value={numDocu}
                        success={numDocu?.length === 8}
                        placeholder='Ingresá sólo números sin puntos'
                        error={numDocu?.length > 0 && numDocu?.length !== 8 && numDocu !== ''}
                        maxLength={8}
                        disabled={!editing}
                    />
                    <div className="perfilHome-container-main-list-item-specialInput" style={{ opacity: numDocu?.length === 8 && editing ? 1 : 0.6}}>
                        <div className="perfilHome-container-main-list-item-specialInput-label">
                            <p className="perfilHome-container-main-list-item-specialInput-label-text bold">Nro de CUIT</p>
                            <p className="perfilHome-container-main-list-item-specialInput-label-requiered">&nbsp;*</p>
                        </div>
                        <div className="perfilHome-container-main-list-item-specialInput-main">
                            <input
                                className="perfilHome-container-main-list-item-specialInput-main-input"
                                style={{ width: '15%' }}
                                maxLength={2}
                                disabled={numDocu?.length !== 8}
                                onChange={(e) => setCuitFirst(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={cuitFirst}
                                placeholder='00'
                            />
                            <b className="perfilHome-container-main-list-item-specialInput-main-line light large">-</b>
                            <input
                                className="perfilHome-container-main-list-item-specialInput-main-input"
                                style={{ width: '70%' }}
                                maxLength={8}
                                disabled
                                value={cuitSecond}
                                placeholder='00000000'
                            />
                            <b className="perfilHome-container-main-list-item-specialInput-main-line light large">-</b>
                            <input
                                className="perfilHome-container-main-list-item-specialInput-main-input"
                                style={{ width: '15%' }}
                                maxLength={1}
                                disabled={numDocu?.length !== 8}
                                onChange={(e) => setCuitThird(e.target.value.trimStart().replace(/[^0-9]+/g, ''))}
                                value={cuitThird}
                                placeholder='0'
                            />
                            <div className="perfilHome-container-main-list-item-specialInput-main-status">
                                {cuitFirst?.length === 2 && cuitSecond?.length === 8 && cuitThird?.length === 1 &&
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusSuccessPrimary)', fontSize: 20}}>done</span>
                                }
                                {cuitSecond?.length === 8 && (cuitFirst?.length !== 2 || cuitThird?.length !== 1) &&
                                    <span className="material-symbols-outlined" style={{ color: 'var(--statusErrorPrimary)', fontSize: 20}}>error</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: editing ? 1 : 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Sexo&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Sexo'
                            list={sexoList}
                            onValueSelected={(a: any) => setSexo(a.codigo)}
                            selectedItem={sexoList && sexo ? sexoList?.filter((a: any) => a.codigo == sexo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu sexo'
                            deleteSelectedItem={() => setSexo(null)}
                            disabled={!sexoList && !editing}
                        /> 
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: editing ? 1 : 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Estado civil&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'} 
                            id='Selector Estado Civil' 
                            list={estadoCivilList}
                            onValueSelected={(a: any) => setEstadoCivil(a.codigo)}
                            selectedItem={estadoCivilList && estadoCivil ? estadoCivilList?.filter((a: any) => a.codigo == estadoCivil)[0]?.descripcion : ''} 
                            placeholder='Seleccioná tu estado civil' 
                            deleteSelectedItem={() => setEstadoCivil(null)}
                            disabled={!estadoCivilList && !editing}
                        />
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: editing ? 1 : 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Nacionalidad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Nacionalidad'
                            list={nacionalidadList}
                            onValueSelected={(a: any) => setNacionalidad(a.codigo)}
                            selectedItem={nacionalidadList && nacionalidad ? nacionalidadList?.filter((a: any) => a.codigo == nacionalidad)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu nacionalidad'
                            deleteSelectedItem={() => setNacionalidad(null)}
                            disabled={!nacionalidadList && !editing}
                        /> 
                    </div>
                    <CustomTextInput
                       label='Celular'
                       required
                       maxLength={10}
                       changeText={(e: string) => setCelular(e.replace(/[^0-9]+/g, ''))}
                       value={celular}
                       success={celular !== ''}
                       disabled
                    />
                    <div className="perfilHome-container-main-list-invis"></div>
                </div>
                <h6 className="perfilHome-container-main-titulo bold" style={{marginBottom: 24}}>Datos de domicilio</h6>
                <div className="perfilHome-container-main-list">
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: editing ? 1 : 0.5 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Tipo de domicilio&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Tipo de domicilio'
                            list={tipoDomiList}
                            onValueSelected={(a: any) => setTipoDomi(a.codigo)}
                            selectedItem={tipoDomiList && tipoDomi ? tipoDomiList?.filter((a: any) => a.codigo == tipoDomi)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu tipo de domicilio'
                            deleteSelectedItem={() => setTipoDomi(null)}
                            disabled={!tipoDomiList && !editing}
                        /> 
                    </div>
                    <CustomTextInput
                        label='Calle'
                        required
                        value={calle}
                        success={calle?.length > 0}
                        changeText={(e: string) => setCalle(e)}
                        disabled={!editing}
                    />
                    <div className="perfilHome-container-main-list-list">
                    <CustomTextInput
                            label='Nro'
                            required
                            changeText={(e: string) => setNro(e.replace(/[^0-9]+/g, ''))}
                            value={nro}
                            success={nro?.length > 0}
                            disabled={!editing}
                        />
                        <CustomTextInput
                            label='Piso'
                            changeText={(e: string) => setPiso(e)}
                            value={piso}
                            success={piso?.length > 0}
                            disabled={!editing}
                        />
                        <CustomTextInput
                            label='Dpto.'
                            changeText={(e: string) => setDpto(e)}
                            value={dpto}
                            success={dpto?.length > 0}
                            disabled={!editing}
                        />
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: !provinciaList || !editing ? 0.5 : 1 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Provincia&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Provincia'
                            list={provinciaList}
                            onValueSelected={(a: any) => {
                                setProvincia(a)
                                props.getLocalidadesByProvinciaPardo(setLocalidadList, a.codigo)
                            }}
                            selectedItem={provinciaList && provincia ? provinciaList?.filter((a: any) => a.codigo == provincia?.codigo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu provincia'
                            deleteSelectedItem={() => {
                                setProvincia(null)
                                setLocalidad(null)
                                setLocalidadList(null)
                                setCodigoPostal(null)
                                setCodigosPostalesList(null)
                            }}
                            disabled={!provinciaList || !editing}
                        /> 
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: !localidadList || !editing ? 0.5 : 1 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Localidad&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Localidad'
                            list={localidadList}
                            onValueSelected={(a: any) => {
                                setLocalidad(a)
                                props.getCodigosPostalesByLocalidadPardo(setCodigosPostalesList, a.codigo)
                            }}
                            selectedItem={localidadList && localidad ? localidadList?.filter((a: any) => a.codigo == localidad?.codigo)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu localidad'
                            deleteSelectedItem={() => {
                                setLocalidad(null)
                                setCodigoPostal(null)
                                setCodigosPostalesList(null)
                            }}
                            disabled={localidadList?.length === 0 || !editing}
                        /> 
                    </div>
                    <div className="perfilHome-container-main-list-item-selectorWrapper" style={{ opacity: !codigosPostalesList || !editing ? 0.5 : 1 }}>
                        <h6 className="perfilHome-container-main-list-item-selectorWrapper-title bold">Código postal&nbsp;<ul>*</ul></h6>
                        <SelectorSearcher
                            widthList={isTabletOrMobile ? '87.1%' : '31.4%'}
                            id='Selector Codigo Postal'
                            list={codigosPostalesList}
                            onValueSelected={(a: any) => setCodigoPostal(a.codigo)}
                            selectedItem={codigosPostalesList && codigoPostal ? codigosPostalesList?.filter((a: any) => a.codigo == codigoPostal)[0]?.descripcion : ''}
                            placeholder='Seleccioná tu codigo postal'
                            deleteSelectedItem={() => setCodigoPostal(null)}
                            disabled={codigosPostalesList?.length === 0 || !editing}
                        /> 
                    </div>
                </div>
                <h6 className="perfilHome-container-main-titulo bold" style={{marginBottom: 24}}>Datos de cuenta</h6>
                <div className="perfilHome-container-main-list">
                    <CustomTextInput
                        label='E-mail'
                        required
                        value={email}
                        placeholder='nombre@ejemplo.com'
                        disabled
                    />
                    <CustomTextInput
                        label='Contraseña'
                        required
                        value='**********'
                        changePasswordClick={() => navigate('/change-password')}
                    />
                    <div className="perfilHome-container-main-list-invis"></div>
                </div>
            </div>
            {editing ?
                <div className="perfilHome-container-main-edit-buttons">
                    <MainButton
                        id='CANCELAR'
                        border
                        color='var(--primary500)'
                        text='CANCELAR'
                        onButtonPressed={() => setEditing(false)}
                    />
                    <MainButton
                        id='GUARDAR'
                        fill
                        color='var(--primary500)'
                        text='GUARDAR'
                        onButtonPressed={() => {
                            const dateGoodFormat = Moment(fechaNac, 'YYYY-MM-DD').format('YYYY-MM-DD')
                            const data = {
                                'name': nombre,
                                'lastName': apellido,
                                'birthDate': dateGoodFormat,

                                'documentId': tipoDocu,
                                'documentNumber': numDocu,
                                'cuil': cuitFirst + cuitSecond + cuitThird,

                                'sexId': sexo,
                                'civilStatus': estadoCivil,
                                'nacionalityId': nacionalidad,
                                
                                'phone': celular,
                                'userName': email,

                                'addressType': tipoDomi,
                                'addressStreet': calle,
                                "addressNumber": nro,
                                'addressFloor': piso,
                                'dpto': dpto,
                                
                                'provincia': parseInt(provincia?.codigo, 10),
                                'provinciaText': provincia?.descripcion,
                                'localidad': parseInt(localidad?.codigo, 10),
                                'localidadText': localidad?.descripcion,
                                'addressPostalCode': codigoPostal,
                            }
                            props.saveEditedData(authUserId, data, setEditing, setNotification)
                        }}
                        disabled={isDisabled()}
                    />
                </div>
                : 
                <div 
                    onClick={() => setEditing(true)}
                    className="perfilHome-container-main-edit-accion"
                >
                    <span className="material-symbols-outlined" style={{ fontSize: 24, color: 'var(--white)' }}>edit</span>
                </div>
            }
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    userAccessToken: state.general.userAccessToken
  }; 
};

export default connect(mapStateToProps, {
    getDataForNewUserArgPardo,
    getLocalidadesByProvinciaPardo,
    getCodigosPostalesByLocalidadPardo,
    loadDataForLoggedUserArgPardo,
    saveEditedData
})(PerfilHome);