import React from 'react'
import './CategoriaButton.css'
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

const CategoriaButton = (props: any) => {
 
  return (
    <div className="categoriaButton-container">
      <img 
        className="categoriaButton-container-img"
        src={props.image} alt=""
        onClick={() => {if(!props.commingSoon) props.categoryPressed()}}
        id={props.id}
      />
      {props.tag && <b className="categoriaButton-container-tag regular small">{props.tag}</b>}
    </div>
  );

} 
 
export default CategoriaButton;