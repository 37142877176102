import { ReactComponent as Argentina } from './/assets/images/svgs/argentinaLogo.svg';

import Whatsapp from "./assets/logo/Whatsapp.svg";
import WhatsappC from "./assets/logo/WhatsappC.svg";
import WhatsappImg from "./assets/images/instagram.png";

import Linkedin from "./assets/logo/Linkedin.svg";
import LinkedinC from "./assets/logo/LinkedinC.svg";
import LinkedinImg from "./assets/images/linkedin.png";

export const countries = [
  {
    title: "ARGENTINA",
    id: 1,
    img: Argentina,
    url: '/',
    lang: () => import('./assets/lang/es-AR-Broker.json'),
    htmlMetaInfo: {
      title: 'Confiá en tu bienestar con WeCover broker'
    },
  }
];

export const categorias = null;

export const burgerMenuList = (props: any, navigate: any, path: any, setAtencionAlert: any) => [
  {
    id: 1,
    label: 'Home',
    icon: 'home',
    action: () => {
      props.setBurgerOpen(false)
      if (props.userAccessToken) {
        navigate('/home')
      } else {
        navigate('/inicio')
      }
    },
    selected: props.userAccessToken ? path === '/home' : path === '/inicio'
  },
  {
    id: 2,
    label: 'Mi perfil',
    icon: 'account_circle',
    action: () => {
      props.setBurgerOpen(false)
      navigate('/perfil')
    },
    selected: path === '/perfil',
    disabled: !props.userAccessToken
  },
  {
    id: 3,
    label: 'Mis coberturas',
    icon: 'lock',
    action: () => {
      props.setBurgerOpen(false)
      navigate('/mis_coberturas#Pendiente')
    },
    selected: path === '/mis_coberturas',
    disabled: !props.userAccessToken
  },
  {
    id: 4,
    label: 'Mis siniestros',
    icon: 'e911_emergency',
    action: () => {
        props.setBurgerOpen(false)
        navigate('/reclamos')
    },
    selected: path === '/reclamos',
    disabled: !props.userAccessToken
  },
  {
    id: 5,
    label: 'Cotizá tu cobertura',
    icon: 'verified_user',
    action: () => {
      props.setBurgerOpen(false)
      if (props.userAccessToken) {
        navigate('/home')
      } else {
        navigate('/inicio')
      }
      props.setRefCategory(true)
    }
  },
  {
    id: 6,
    label: 'Preguntas frecuentes',
    icon: 'quiz',
    action: () => {
      navigate('/inicio')
      props.setBurgerOpen(false)
      props.setRefQuestion(true)
    },
    disabled: props.userAccessToken
  },
  {
    id: 7,
    label: 'Contactanos',
    icon: 'contact_support',
    action: () => {
      props.setBurgerOpen(false)
      navigate('/contacto')
    },
    selected: path === '/contacto',
    disabled: props.userAccessToken
  },
  {
    id: 8,
    label: 'Atención al cliente',
    icon: 'contact_support',
    action: () => {
      props.setBurgerOpen(false)
      setAtencionAlert(true)
    },
    disabled: !props.userAccessToken
  }
]

export const socialLinks = [
  {
    svg: Whatsapp,
    svgC: WhatsappC,
    img: WhatsappImg,
    link: "https://api.whatsapp.com/send/?phone=%2B5491169602118&text=Hola+WeCover!+%F0%9F%91%8B+Quer%C3%ADa+consultarles+sobre+&app_absent=0",
  },
  {
    svg: Linkedin,
    svgC: LinkedinC,
    img: LinkedinImg,
    link: "https://www.linkedin.com/company/wecoverok",
  },
];