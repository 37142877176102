
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import './CategoryListSection.css'
import CategoriaButton from '../../../components/cotizarComponents/CategoriaButton/CategoriaButton';
import { useNavigate } from 'react-router-dom';

let isTabletOrMobile = false;
const CategoryListSection = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });

  const navigate = useNavigate();

  const getCategoryImage = (categoryId: any) => {
    switch (categoryId) {
      case 18:
        return 'https://resources.wecover.co/broker/Salud-integral.jpg';
      case 19:
        return 'https://resources.wecover.co/broker/Salud-mujer.jpg';
      case 20:
        return 'https://resources.wecover.co/broker/Salud-senior.jpg';
      case 21:
        return 'https://resources.wecover.co/broker/Robo-contenido.jpg';
      default:
        return 'https://resources.wecover.co/broker/Salud-integral.jpg';
    }
  }

  return (
    <div className="categoryListSection-container" ref={props.refCategory}>
      {props.categoriaList?.length !== 0 && 
        <div className='categoryListSection-container-text'>
          <h4 className='categoryListSection-container-text-title bold'>¿Qué cobertura estás buscando?</h4>
          <b className='categoryListSection-container-text-subtitle regular large'>Descubrí las mejores opciones que tenemos para vos</b>
        </div>
      }
      <div className='categoryListSection-container-list'>
        {props.categoriaList?.map((categoryDB: any, index: Number) => (
          <CategoriaButton
            key={index}
            id={categoryDB.name + ' boton'}
            name={categoryDB.name}
            tag={categoryDB.tagText}
            image={getCategoryImage(categoryDB.category.id)}
            categoryPressed={() => {
              props.categoriaSelectedChange(categoryDB.category.id)
              const adminRoute = props.admin ? '&admin=True' : ''
              navigate('/oferta?productId=' + categoryDB.individualProduct + adminRoute)
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default CategoryListSection;