

import React from 'react'
import './AtencionAlert.css'
import MainButton from '../../generalComponents/MainButton/MainButton';

const AtencionAlert = (props: any) => {
  return (
    <div className="atencionAlert-container">
        <div className="atencionAlert-container-close" onClick={() => props.close()}>
            <span className="material-symbols-outlined">close</span>
        </div>
        <div className="atencionAlert-container-data">
            <p className="bold" style={{ color: 'var(--primary500)', textAlign: 'center' }}>¿CÓMO PODEMOS AYUDARTE?</p>
        </div>
        <div className="atencionAlert-container-data">
            <b className="regular large" style={{ color: 'var(--gray500)', textAlign: 'center' }}>Comunicate con nuestro canal de atención</b>
            <b className="regular large" style={{ color: 'var(--gray500)', textAlign: 'center' }}>de Lunes a Viernes de 9 a 18 h a través de Whatsapp</b>
        </div>
        <div className="atencionAlert-container-botton">
            <MainButton
                id='CONTACTARME'
                fill
                color='var(--secondary500)'
                text='CONTACTARME'
                onButtonPressed={() => {
                    props.close()
                    window.open("https://api.whatsapp.com/send/?phone=%2B5491169602118&text=Hola+WeCover!+%F0%9F%91%8B+Quer%C3%ADa+consultarles+sobre+&app_absent=0")
                }}
            />
        </div>
    </div>
  );
}

export default AtencionAlert;