import { useEffect, useRef, useState } from 'react';
import './BurgerMenu.css';
import { useMediaQuery } from 'react-responsive';
import { connect, useDispatch } from 'react-redux';
import {
    setBurgerOpen,
    userAccessTokenSet,
    setRefCategory,
    setRefQuestion,
    setAccessTokenAux
} from '../../../actions';
import { ReactSVG } from 'react-svg';
import DefaultAvatar from '../../../assets/images/svgs/defaultAvatar.svg'; 
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { GetCountryUrl } from '../../../util/GetCountryUrl';
import Modal from 'react-modal';
import AtencionAlert from '../../alertComponents/AtencionAlert/AtencionAlert';
import CerrarSesionAlert from '../../alertComponents/CerrarSesionAlert/CerrarSesionAlert';
import { CHECKOUT_DATA, USER_DATA } from '../../../actions/types';
import BurgerMenuItem from './BurgerMenuItem/BurgerMenuItem';
import { burgerMenuList } from '../../../data';

let isTabletOrMobile = false;

const BurgerMenu = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const navigate = useNavigate();
    const mainRef: any = useRef();
    const dispatch = useDispatch();
    const [atencionAlert, setAtencionAlert] = useState<boolean>(false);
    const [cerrarSesionAlert, setCerrarSesionAlert] = useState<boolean>(false);
    const [translate, setTranslate] = useState<any>(null);
    const location: any = useLocation();
    const path = location ? location?.pathname : '';

    const avatarSize = isTabletOrMobile ? 100 : 125;

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                props.setBurgerOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);

    useEffect(() => {
        setTranslate(window.screen.width-mainRef?.current?.offsetWidth)
    }, [window?.screen?.width, mainRef])

    const atencionModal = () => {
        return (
          <Modal
            isOpen={atencionAlert}
            onRequestClose={() => setAtencionAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <AtencionAlert
              close={() => setAtencionAlert(false)}
            />
          </Modal>
        )
    }

    const cerrarSesionModal = () => {
        return (
          <Modal
            isOpen={cerrarSesionAlert}
            onRequestClose={() => setCerrarSesionAlert(false)}
            style={{
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: isTabletOrMobile ? '80%' : '30%',
                padding: isTabletOrMobile ? '10px' : '20px',
              },
              overlay: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }
            }}
          >
            <CerrarSesionAlert
                onPress={() => {
                    props.userAccessTokenSet(null);
                    dispatch(setAccessTokenAux(null));
                    dispatch({ type: USER_DATA, payload: null });
                    dispatch({ type: CHECKOUT_DATA, payload: null });
                    navigate(GetCountryUrl(props.countryId) + 'inicio')
                }}
                close={() => setCerrarSesionAlert(false)}
            />
          </Modal>
        )
    }

    return (
        <div
            ref={mainRef}
            className="burgerMenu-container"
            style={{ transform: props.burgerMenuOpen ? `translate(${translate}px, 0px)` : `translate(${window.screen.width}px, 0px)` }}
        >
            <div className="burgerMenu-container-top">
                <span onClick={() => props.setBurgerOpen(false)} className="material-symbols-outlined" style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 20, fontSize: 40, color: 'var(--white)' }}>close</span>
                {props.userAccessToken && props.userData?.imageProfile ?
                    <img src={props.userData?.imageProfile} className="burgerMenu-container-top-profileImg" />
                    :
                    <ReactSVG
                        src={DefaultAvatar}
                        beforeInjection={svg => {
                            svg.setAttribute('style', 'height: ' + avatarSize);
                            svg.setAttribute('style', 'width: ' + avatarSize);
                        }}
                    />
                }
                {props.userAccessToken && props.userData ?
                    <div className="burgerMenu-container-top-user">
                        <h3 className="burgerMenu-container-top-user-name bold">{props.userData.name + ' ' + props.userData.lastName}</h3>
                        <p className="burgerMenu-container-top-user-email regular">{props.userData.username}</p>
                    </div>
                    :
                    <Link
                        className="burgerMenu-container-top-ingresar"
                        onClick={() => props.setBurgerOpen(false)} 
                        to={GetCountryUrl(props.countryId) + 'login?'} 
                        state={{ login: 'InicioSesion' }} 
                    >
                        <p className="burgerMenu-container-top-ingresar-text">INGRESAR</p>
                    </Link>
                }
            </div>
            <div className="burgerMenu-container-main">
                {burgerMenuList(props, navigate, path, setAtencionAlert)?.map((a: any, index: any) =>(
                    <BurgerMenuItem
                        key={index}
                        label={a.label}
                        icon={a.icon}
                        action={a.action}
                        isSelected={a.selected}
                        disabled={a.disabled}
                    />
                ))}
            </div>
            {props.userAccessToken && props.userData && <div className="burgerMenu-container-bot">
                <h6
                    className="burgerMenu-container-bot-text regular"
                    onClick={() => {
                        props.setBurgerOpen(false)
                        setCerrarSesionAlert(true)
                    }}
                >Cerrar sesión</h6>
            </div>}
            {atencionModal()}
            {cerrarSesionModal()}
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
        burgerMenuOpen: state.general.burgerMenuOpen,
        userData: state.user.userData,
        userAccessToken: state.general.userAccessToken,
    };
};

export default connect(mapStateToProps, {
    setBurgerOpen,
    userAccessTokenSet,
    setRefCategory,
    setRefQuestion
})(BurgerMenu);