import React from 'react'
import './Notification.css'

const Notification = (props: any) => {

    return (
        <div 
            className='notification-container'
            style={{
                backgroundColor: props.notification?.success ? '#E6F3ED' : '#FDEEE7',
                border: props.notification.success ? '1px solid var(--statusSuccessPrimary)' : '1px solid var(--statusWarningPrimary)',
                transform: props.notification.text === '' ? `translate(1000px, 0px)` : `translate(0px, 0px)`
            }}
        >
            <span 
                className="material-symbols-outlined" 
                style={{ color: props.notification?.success ? 'var(--statusSuccessPrimary)' : 'var(--statusWarningPrimary)'}}
            >{props.notification?.success ? 'priority' : 'emergency_home'}</span>
            <b className='notification-container-text regular small' >{props.notification?.text}</b>
            <span 
                className="material-symbols-outlined" 
                onClick={() => props.setNotification({success: false, text: ''})} 
                style={{ cursor: 'pointer', color: 'var(--gray600)' }}
            >close</span>
        </div>
    );
}

export default Notification;