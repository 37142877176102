import React from "react";
import { useNavigate } from "react-router-dom";
import PBOLogo from "../../../assets/images/svgs/logoBlanco.svg";
import { socialLinks } from "../../../data";
import "./Footer.css";
import { useMediaQuery } from "react-responsive";
import { GetCountryUrl } from "../../../util/GetCountryUrl";
import { GetGlobalParams } from "../../../util/GetGlobalParams";
import { setRefCategory, setRefQuestion } from "../../../actions";
import { connect } from "react-redux";
import logo_superintendencia from '../../../assets/images/svgs/logo_superintendencia.svg'
import { ReactSVG } from 'react-svg';

let isTabletOrMobile = false;
const Footer = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const navigate = useNavigate();

  const iconSize = 32

  return (
    <div className="Footer-container">
      <div className="Footer-container-wrap">
        <div className="Footer-container-wrap-main">
          <div className="Footer-container-wrap-content">
            <div className="Footer-container-wrap-content-links">
              <img src={PBOLogo} alt="wecover logo" className="Footer-container-wrap-content-links-logo" onClick={() => navigate("/")}/>
              <div className="Footer-container-wrap-content-links-list">
                {socialLinks?.map((redSocial: any, index: any) => (
                  <a key={index} href={redSocial.link} target="_blank" rel="noreferrer">
                    <ReactSVG 
                        src={redSocial.svgC}
                        style={{marginRight: 16}}
                        beforeInjection={svg => {
                            svg.setAttribute('style', 'height: ' + iconSize);
                            svg.setAttribute('style', 'width: ' + iconSize);
                        }}
                    />
                  </a>
                ))}
              </div>
            </div>
            <div className="Footer-container-wrap-content-section">
              <b className="Footer-container-wrap-content-section-title regular large">Menú</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/")}>Home</b>
              <b 
                className="Footer-container-wrap-content-section-link regular large" 
                onClick={() => {
                  navigate(GetCountryUrl(props.countryId) + 'inicio')
                  props.setRefCategory(true)
                }}
              >Cotizá tu cobertura</b>
              <b 
                className="Footer-container-wrap-content-section-link regular large" 
                onClick={() => {
                  navigate(GetCountryUrl(props.countryId) + 'inicio')
                  props.setRefQuestion(true)
                }}
              >Preguntas frecuentes</b>
              
            </div>
            <div className="Footer-container-wrap-content-section">
              {!isTabletOrMobile && <b className="Footer-container-wrap-content-section-title regular large"></b>}
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/contacto")}>Contactanos</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/login")}>Ingresar</b>
            </div>
          </div>
          <div className="Footer-container-wrap-info">
            <div className="Footer-container-wrap-info-rights">
              {isTabletOrMobile ?
                <b className="Footer-container-wrap-info-rights-law regular medium">{process.env.REACT_APP_NOMBRE} © {new Date().getFullYear()} - Todos los derechos reservados</b>
                :
                <b className="Footer-container-wrap-info-rights-law regular small">{process.env.REACT_APP_NOMBRE} © {new Date().getFullYear()} - Todos los derechos reservados</b>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-container-legals">
        <div className="Footer-container-legals-wrap">
          <div className="Footer-container-legals-data">
            <div className="Footer-container-legals-data-first">
              <div className="Footer-container-legals-data-first-noColorCont">
                <p className="Footer-container-legals-data-first-noColorCont-noColor">N° de matrícula SSN<br/>301</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-noColorCont-noColor">Departamento de Orientación<br/>y Asistencia al Asegurado</p>
              </div>
              <div className="Footer-container-legals-data-first-colorCont">
                <p className="Footer-container-legals-data-first-colorCont-color">0800-666-8400</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-colorCont-color">www.argentina.gob.ar/ssn</p>
              </div>
            </div>
            <div className="Footer-container-legals-data-superImg">
              <img className="Footer-container-legals-data-superImg-img" src={logo_superintendencia}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
      countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  setRefCategory,
  setRefQuestion
})(Footer);